import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { Box } from '@primer/react';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const KeyboardKey = makeShortcode("KeyboardKey");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <Note mdxType="Note">
      <p>{`  The `}<a parentName="p" {...{
          "href": "/components/underline-nav"
        }}>{`underline nav`}</a>{` guidelines cover the design guidelines not mentioned here.`}</p>
      <p>{`  The guidelines on this page only highlight the differences between underline panels and underline nav.`}</p>
    </Note>
    <h2>{`Usage`}</h2>
    <p>{`Underlined panels let users switch between 2 or more related panels of content without changing the URL or leaving their current context.`}</p>
    <p>{`If you want to use this pattern for tabs that change the URL when activated, use the `}<a parentName="p" {...{
        "href": "/components/underline-nav"
      }}>{`underline nav`}</a>{` component instead.`}</p>
    <h3>{`Best practices`}</h3>
    <ul>
      <li parentName="ul">{`One of the tabs should be selected by default when the user loads the page.`}</li>
      <li parentName="ul">{`Avoid overwhelming the user with too many tabs. If you have too many tabs, try grouping your content into broader categories or exposing more of the content on the page without requiring the user to click into a tab.`}</li>
      <li parentName="ul">{`Each view should have enough information so the user can complete their tasks without switching back and forth between tabs.`}</li>
      <li parentName="ul">{`Views should be able to be navigated in any order. This is not a pattern for navigating stepped flows.`}</li>
    </ul>
    <h2>{`Accessibility`}</h2>
    <Box display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 3
    }} mdxType="Box">
      <div>
        <p>{`Underline panels are made of the following ARIA roles:`}</p>
        <ul>
          <li parentName="ul"><inlineCode parentName="li">{`tablist`}</inlineCode>{`: the container wrapping the tabs`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`tab`}</inlineCode>{`: each individual tab`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`tabpanel`}</inlineCode>{`: the container wrapping the content associated with each tab`}</li>
        </ul>
        <p>{`The active tab is conveyed to assistive technologies using the `}<inlineCode parentName="p">{`aria-selected`}</inlineCode>{` attribute. Each tab is associated with it's corresponding panel using the `}<inlineCode parentName="p">{`aria-controls`}</inlineCode>{` attribute.`}</p>
        <p>{`Icons in `}<a parentName="p" {...{
            "href": "https://primer.style/components/underline-panels/react/draft#underlinepanelstab"
          }}>{`UnderlinePanels.Tab`}</a>{` are purely decorative and are hidden from screen readers by default. If the icon does convey any informational meaning, the adjacent text inside of the tab must provide all the necessary information without relying on the icon itself.`}</p>
      </div>
      <img width="456" role="presentation" src="https://github.com/primer/design/assets/2313998/63c0312a-1e74-4990-b4bf-1795e5f3640d" />
    </Box>
    <h3>{`Keyboard navigation`}</h3>
    <p>{`Underline panels' tabs follow the `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/patterns/tabs/examples/tabs-automatic/"
      }}>{`automatic activation pattern`}</a>{`. This means that when a tab receives focus, the tab is activated and the associated panel is displayed.`}</p>
    {/* TODO: revise this guidance once we change to the manual activation pattern. This was discussed in A11y Office Hours on 02/27/2024: https://github.com/github/accessibility/issues/5623#issuecomment-1967930432 */}
    {/* Underline panels' tabs follow the [manual activation pattern](https://www.w3.org/WAI/ARIA/apg/patterns/tabs/examples/tabs-manual/). This decision was made so users don't unintentionally trigger the side effects of rendering a new tab panel while they're navigating the tab list. For example, activating a new tab could trigger a network request to fetch new content. */}
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Key(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><KeyboardKey mdxType="KeyboardKey">{`Enter`}</KeyboardKey>{` or `}<KeyboardKey mdxType="KeyboardKey">{`Space`}</KeyboardKey></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Activates the focused tab.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><KeyboardKey mdxType="KeyboardKey">{`Tab`}</KeyboardKey></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When focus moves into the tab list, focus goes to the active tab. `}<br />{` When the tab list contains focus, moves focus to the next element in the page tab sequence outside the tablist.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><KeyboardKey mdxType="KeyboardKey">{`Arrow`}{` `}{`Left`}</KeyboardKey></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Moves focus to the previous tab. If focus is on the first tab, focus moves to the last tab. `}<br />{` The focused tab is activated.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><KeyboardKey mdxType="KeyboardKey">{`Arrow`}{` `}{`Right`}</KeyboardKey></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Moves focus to the next tab. If focus is on the last tab, focus moves to the first tab. `}<br />{` The focused tab is activated.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><KeyboardKey mdxType="KeyboardKey">{`Home`}</KeyboardKey></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Moves focus to the first tab and activates it.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><KeyboardKey mdxType="KeyboardKey">{`End`}</KeyboardKey></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Moves focus to the last tab and activates it.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="UnderlinePanels" mdxType="AccessibilityLink" />
    <h2>{`Related links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/segmented-control"
        }}>{`Segmented control`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/underline-panels"
        }}>{`Underline panels`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/ui-patterns/navigation"
        }}>{`Navigation`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      